.offer-editor-form {
  .ui.corner.label .asterisk.icon {
    top: -7px;
    color: red;
  }

  .ui.ribbon.label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ui.top.attached.menu {
    .item {
      background: rgba(0, 0, 0, .05) !important;
    }

    .active.item {
      background: rgba(0, 0, 0, 0) !important;
    }
  }

  .stack-elements {
    display: flex;
    flex-direction: column;

    .left.menu {
      align-items: center;
      justify-content: center;
    }

    .left.menu, .right.menu {
      display: flex;
      flex-direction: column;

      .item {
        &:before {
          width: 0;
        }
      }
    }
  }

  .ui.label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    height: fit-content;
    width: fit-content;
  }

  .icon.button {
    i.icon {
      color: white;
      opacity: 1;
    }
  }

  .ui.icon.header {
    height: auto;
    padding: 10px;
  }

  .button {
    i.icon {
      color: white;
      opacity: 1;
    }
  }

  .offer-section {

  }

  .offer-element {
    @media only screen and (max-width: 1450px) {
      &.menu {
        display: flex;
        flex-direction: column;

        .right.menu:not(.vertical) {
          margin-left: 0 !important;
        }
      }
    }
  }

  .offer-descriptions {
    @media only screen and (max-width: 1600px) {
      tr {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .offer-properties {
    @media only screen and (max-width: 1200px) {
      tr {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .clndr-control {
    i {
      color: black !important;
    }
  }

  .card-header.raised.border-0 {
    button {
      //text-transform: uppercase;
    }
  }

  .github-picker {
    margin-left: 15px !important;
    box-shadow: none !important;
    margin-top: 15px !important;
  }
}

.sticky-menu-buttons {
  position: sticky !important;
  top: 80px;
  z-index: 15;
}
