.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  height: 40px;
  width: 64px;
  line-height: 1;
  font-size: 20px;
  position: relative;

  i:hover {
    cursor: default;
  }
}

.imageWrapperError {
  border: 1px solid #ff9696;
  background: #ffcfcf;
}

.tooltip {
  position: absolute;
  top: -7px;
  right: -7px;
  font-size: 14px;
  line-height: 1;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;

  i {
    margin: 0 !important;
  }
}

.thumbImage {
  height: 40px;
  width: 64px;
  object-fit: cover;
  border-radius: 4px;
}
